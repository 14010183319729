import { useEffect, useState, useRef } from "react";
import { Button, makeStyles } from "@material-ui/core";
import OtpInput from "react-otp-input";
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import "../../assets/css/index.css";
import { BASE_URl, postApiCall, postApiCallStringyfy } from "../../api/api";
import ShowModal from "./modal";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { countryData } from "../../Home";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 250,
    borderRadius: 30,
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
    "@media (max-width: 750px)": {
      width: 300,
    },
  },
  formControlFull: {
    // margin: theme.spacing(1),
    marginTop: 20,
    borderRadius: 30,
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
    "@media (max-width: 750px)": {
      // width: 300,
    },
  },
  textField: {
    // margin: theme.spacing(1),
    border: "none",
    borderRadius: 30,
    backgroundColor: "#fff",
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
    "@media (max-width: 700px)": {
      maxWidth: "100%",
    },
  },
  otpButton: {
    width: "30%",
    fontSize: "15px",
    borderRadius: "87px",
    textTransform: "capitalize",
    backgroundColor: "#148108",
    // padding: '11px',
    // marginRight: '-10px',
  },

  getOtpButton: {
    width: "100%",
    fontSize: "15px",
    borderRadius: "50px",
    marginLeft: 5,
    textTransform: "capitalize",
    backgroundColor: "#148108",
    // padding: '11px',
    // marginRight: '-10px',
  },

  submitOtpButton: {
    width: "30%",
    fontSize: "15px",
    borderRadius: "87px",
    textTransform: "capitalize",
    backgroundColor: "#12B800",
    // color: otpBtnDisable ? "#fff" : "#000",
    padding: "3px",
    marginRight: "10px",
    border: "1px solid white",
    "@media (max-width: 700px)": {
      marginTop: "1rem",
      width: "40%",
    },

    "&:visited": {
      Color: "#fff",
    },
  },
  otpBoxes: {
    width: "30px",
    marginLeft: "15px",
    "@media (max-width: 700px)": {
      // width: '15px',
      // marginLeft: '30px',
    },
  },
  submitButton: {
    width: "30%",
    fontSize: "15px",
    borderRadius: "87px",
    textTransform: "capitalize",
    backgroundColor: "#FFAF10",
    padding: "3px",
    marginRight: "10px",
    border: "1px solid white",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  resendButton: {
    backgroundColor: "#118f45",
    fontSize: 13,
    fontWeight: "bold",
    color: "#fff",
    textTransform: "capitalize",
    marginTop: 10,
    padding: 8,
    borderRadius: 15,
    "&:hover": {
      backgroundColor: "#FFAF10",
    },
  },
}));

const gradeList = [
  { value: "1" },
  { value: "2" },
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
  { value: "8" },
  { value: "9" },
  { value: "10" },
  { value: "11" },
  { value: "12" },
];

const StudentForm = () => {
  const [data, setData] = useState("0");
  const { countryName, site_id } = useParams();

  useEffect(() => {
    if (countryName.toLowerCase() === "india") setData("1");
    getData();
    
  }, [countryName]);

  const classes = useStyles();

  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);

  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [checkIndia, setCheckIndia] = useState(true);
  const [schoolById, setSchoolById] = useState([])
  const [selectedState, setSelectedState] = useState({})

  const [emailFieldDisable, setEmailFieldDisable] = useState(false);
  // const [otpBtnDisable, setOtpBtnDisable] = useState(false);
  const [getOtpBtnDisability, setGetOtpBtnDisability] = useState(false);
  const [verifiedBtnDisability, setVerifiedBtnDisability] = useState(false);
  const formRef = useRef();

  const countryList = countryData;

  // timer
  const [countdown, setCountdown] = useState(180); // set initial countdown value to 3 minutes
  const [timerRunning, setTimerRunning] = useState(false); // track whether the timer is running or not
  const [countryDetails, setCountryDetails] = useState([]);

  const [disbilityIndia, setDisbilityIndia] = useState("1");

  useEffect(() => {

    let intervalId;
    if (timerRunning && countdown > 0) {
      intervalId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timerRunning, countdown]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;

  const [showTimer, setShowTimer] = useState(false);
  const [resendBtn, setResedBtn] = useState(false);

  useEffect(() => {
    if (countdown === 0) {
      setResedBtn(true);
      setCountdown(180);

      setTimerRunning(false);
    }
  }, [countdown]);

  const [countryCode, setCountryCode] = useState("");

  const getData = async () => {
    if (countryName.toLowerCase() === "india") {
      setData("1");
      // setDisbilityIndia('1')
    }

    for (let i = 0; i < countryList.length; i++) {
      if (countryName.toLowerCase() === countryList[i].slug.toLowerCase()) {
        setCountryDetails(countryList[i]);
        setCountryCode(countryList[i].id);
        const res = await postApiCall(
          "ncl/getStates",
          "country_id=" + countryList[i].id
        );
        setStateList(res[0]);

        if (countryName.toLowerCase() !== "India".toLowerCase()) {
          onStateChangeForOtherCountry(res[0][0].id, countryList[i].id);
        }
        setState(res[0][0].id);
        setSelectedState({label:res[0][0].name, value:res[0][0].id});
        return;
      }
    }
  };

  const [errorModal, setErrorModal] = useState(false);
  const [title, setTitle] = useState("");
  const [messaage, setMessaage] = useState("");
  const [error, setError] = useState(false);

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [grade, setGrade] = useState("");
  const [name, setName] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (verifiedBtnDisability === false) {
      Swal.fire({
        title: "Error",
        text: "Please verify otp",
        icon: "error",
      });
      return;
    }

    const formdata = new FormData(formRef.current);
    const entries = Array.from(formdata.entries());
    const formValues = entries.reduce(
      (prev, [name, value]) => ({
        ...prev,
        [name]: value,
      }),
      {}
    );

    const domainRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!domainRegex.test(email)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
      });
      return;
    }

    if (phone === "") {
      Swal.fire({
        title: "Error",
        text: "Please enter the phone number",
        icon: "error",
      });
      return;
    }

    // if (formValues.pin < 100000 || formValues.pin > 999999) {
    //     Swal.fire({
    //         title: 'Error',
    //         text: 'Pin code must be 6 digit number!',
    //         icon: 'error'
    //     });
    //     return;
    // }

    // if (data === '1') {
    if (!emailFieldDisable) {
      Swal.fire({
        title: "Error",
        text: "Please verify otp",
        icon: "error",
      });
      return;
    }
    // } else {
    //     if (!otpBtnDisable) {
    //         Swal.fire({
    //             title: 'Error',
    //             text: 'Please verify your Phone Number First',
    //             icon: 'error'
    //         });
    //         return;
    //     }
    // }

    if (disbilityIndia) {
      // const res = await postApiCallStringyfy('ncl/addNewStudent', {
      const res = await postApiCallStringyfy("ncl/addNewStudent", {
        state: state,
        city: city,
        school: schoolName,
        grade: grade,
        name: name,
        mobile: phone,
        email: email,
        country_id: countryCode,
      });
      if (res[0].status === "error") {
        Swal.fire({
          title: "Error",
          text: res[0].response.message,
          icon: "error",
        });
        return;
      }

      process.env.REACT_APP_ENVIRONMENT
        ? (window.location.href = "https://event.icode.org")
        : (window.location.href = "https://2023.dev.icode.org");

      setTitle("Your seat is saved");
      setMessaage(res[0].response.message);
      setError(false);
      setErrorModal(true);
    }

    formRef.current.reset();
    setSchoolList([]);
    setCityList([]);
    setStateList([]);
  };

  const handleSubmitCode = async (e) => {
    e.preventDefault();

    if (otp === "") {
      Swal.fire({
        title: "Error",
        text: "Provide OTP first!",
        icon: "error",
      });
      return;
    }

    // const sendData = {
    //     "otpType": disbilityIndia === '1' ? "mobile" : "email",
    //     "otpTo": disbilityIndia === '1' ? phone : email,
    //     // "otpType": data !== '1' ? "mobile" : 'email',
    //     // "otpTo": data !== '1' ? phone : email,
    //     "otp": otp
    // }
    const sendData = {
      otpType: checkIndia ? "mobile" : "email",
      otpTo: checkIndia ? phone : email,
      otp: otp,
    };

    const res = await postApiCallStringyfy("ncl/verifyOtp", sendData);
    if (res[0].status === "error") {
      Swal.fire({
        title: "Error",
        text: res[0].response.message,
        icon: "error",
      });
      return;
    }

    setResedBtn(false);
    setShowTimer(false);

    setVerifiedBtnDisability(true);
  };

  const handleGetOtp = async (e) => {
    e.preventDefault();

    if (
      state === "" ||
      city === "" ||
      schoolName === "" ||
      grade === "" ||
      name === "" ||
      phone === "" ||
      email === ""
    ) {
      Swal.fire({
        title: "Error",
        text: "Please fill all the fields!",
        icon: "error",
      });
      return;
    }

    // if (data !== '1') {
    //     if (phone === '') {
    //         Swal.fire({
    //             title: 'Error',
    //             text: 'Please enter the phone number',
    //             icon: 'error'
    //         });
    //         return;
    //     }

    //     if (phone < 1000000000 || phone > 9999999999) {
    //         Swal.fire({
    //             title: 'Error',
    //             text: 'Phone Number must be of 10 digit number',
    //             icon: 'error'
    //         });

    //         return;
    //     }
    // } else {
    if (email === "") {
      Swal.fire({
        title: "Error",
        text: "Please enter the Email",
        icon: "error",
      });
      return;
    }
    const domainRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!domainRegex.test(email)) {
      Swal.fire({
        title: "Error",
        text: "Please enter a valid email",
        icon: "error",
      });
      return;
    }
    // }

    const sendData = {
      otpType: checkIndia ? "mobile" : "email",
      otpTo: checkIndia ? phone : email,
    };

    const res = await postApiCallStringyfy("ncl/requestValidation", sendData);
    if (res[0].status === "error") {
      Swal.fire({
        title: "Error",
        text: res[0].response.message,
        icon: "error",
      });
      return;
    }

    setGetOtpBtnDisability(true);
    setShowTimer(true);
    setResedBtn(false);
    setTimerRunning(true);
    // if (data !== '1')
    //     setOtpBtnDisable(true)
    // else
    setEmailFieldDisable(true);
    Swal.fire({
      title: "Successful",
      text: res[0].response.message,
      icon: "success",
    });
  };

  const [stateId, setStateId] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  // getCities from state
  const onStateChange = async (val) => {
    setStateId(val.value);
    setSelectedCity(null);
    setSelectedSchool(null);

    await fetch(BASE_URl + "ncl/getNewCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: val.value,
        country_id: countryCode,
      }),
    })
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Not 200");
        } else {
          const res = await response.json();
          setCityList(res);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });

    // const res = await postApiCall('ncl/getNewCities', "state_id=" + val.value);
    // setCityList([])
    // setCityList(res)
  };

  const onStateChangeForOtherCountry = async (val, countryC) => {
    setStateId(val);
    setSelectedCity(null);
    setSelectedSchool(null);

    await fetch(BASE_URl + "ncl/getNewCities", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        state_id: val,
        country_id: countryC,
      }),
    })
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Not 200");
        } else {
          const res = await response.json();
          setCityList(res);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });

    // const res = await postApiCall('ncl/getNewCities', "state_id=" + val.value);
    // setCityList([])
    // setCityList(res)
  };

  // getSchool from City
  const onCityChange = async (val) => {
    setSelectedSchool(null);
    await fetch(BASE_URl + "ncl/getNewSchools", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        city_id: val.value,
        state_id: stateId,
        country_id: countryCode,
      }),
    })
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Not 200");
        } else {
          const res = await response.json();
          setSchoolList(res);
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });

    // const res = await postApiCall('ncl/getSchools', "city_id=" + val.value);
    // setSchoolList(res)
  };

  const getSchoolsById = async () => {
    await fetch(BASE_URl + "ncl/getSchoolsById", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        site_id,
      }),
    })
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Not 200");
        } else {
          const res = await response.json();
          setSchoolById(res[0])
        }
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  };

  // validation for name
  function handleKeyDown(event) {
    const regex = /^[a-zA-Z\s]*$/;
    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    window.location.pathname &&
      setCheckIndia(window.location.pathname.toLowerCase().includes("india"));
  }, []);

  useEffect(() => {
    site_id && getSchoolsById()
  }, [site_id]);

  useEffect(()=>{
    if (schoolById?.state && stateList) {
        const stateselected = stateList.filter(item => item.id == schoolById?.state)[0]
        setSelectedState({
            label: stateselected?.name,
            value: stateselected?.id
        })
        setState(stateselected?.id)
    }
    if (schoolById?.city && cityList) {
        const cityselected = cityList.filter(item => item.id == schoolById?.city)[0]
        setSelectedCity({
            label: cityselected?.name,
            value: cityselected?.id
        })
        setCity(cityselected?.id)
    }
    if (schoolById?.id && schoolList) {
        const schoolselected = schoolList.filter(item => item.id == schoolById?.id)[0]
        setSelectedSchool({
            label: schoolselected?.name,
            value: schoolselected?.id
        })
        setSchoolName(schoolselected?.id)
    }
  }, [schoolById, cityList, schoolList, stateList])

  useEffect(() => {
    city && onCityChange(selectedCity)
  }, [city]);

  useEffect(() => {
    state && onStateChange({value:state})
  }, [state]);

  return (
    <div>
      {errorModal && (
        <ShowModal
          modalOpen={errorModal}
          setErrorModal={setErrorModal}
          titile={title}
          message={messaage}
          error={error}
          type="student"
        />
      )}
      <form onSubmit={handleSubmit} ref={formRef}>
        <div className="form-container">
          <div className="form-row">
            {data !== "1" ? (
              stateList.length !== 0 && (
                <Form.Group className="mb-3" controlId="formName">
                  <Form.Control
                    type="text"
                    name="state"
                    value={stateList[0].name}
                    className="font-normal"
                    autoComplete="new-password"
                    // disabled={true}
                    disabled={site_id}
                  />
                  <Form.Control.Feedback type="invalid">
                    Please enter School Name.
                  </Form.Control.Feedback>
                </Form.Group>
              )
            ) : (
              <Form.Group className="form-width">
                <Select
                  placeholder="Select State"
                  name="state"
                  value={selectedState}
                  onChange={(val) => {
                    setState(val.value);
                    setSelectedState(val)
                    // onStateChange(val);
                    setSelectedCity(null);
                    setSelectedSchool(null);
                  }}
                  options={stateList?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  required={true}
                  classNamePrefix="filter-select"
                  className="filterButton"
                  autoComplete="new-password"
                  isDisabled={site_id}
                  // isDisabled={disbilityIndia === '1' ? true : emailFieldDisable}
                />

                <Form.Control.Feedback type="invalid">
                  Please select Your State.
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group className="form-width">
              <Select
                placeholder={data === "1" ? "Select City" : "Select Region"}
                name="city"
                value={selectedCity}
                onChange={(val) => {
                  setSelectedCity(val);
                  setCity(val.value);
                //   onCityChange(val);
                  setSelectedSchool(null);
                }}
                options={cityList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                required={true}
                classNamePrefix="filter-select"
                className="filterButton"
                autoComplete="new-password"
                isDisabled={site_id}
              />

              <Form.Control.Feedback type="invalid">
                Please select Your State.
              </Form.Control.Feedback>
            </Form.Group>
          </div>

          <Form.Group>
            <Select
              placeholder="Select School"
              name="school"
              value={selectedSchool}
              onChange={(val) => {
                setSchoolName(val.value);
                setSelectedSchool(val);
              }}
              options={schoolList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              required={true}
              classNamePrefix="filter-select"
              className="filterButton"
              autoComplete="new-password"
              isDisabled={site_id}
              // isDisabled={disbilityIndia === '1' ? true : emailFieldDisable}
            />

            <Form.Control.Feedback type="invalid">
              Please select School.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Select
              placeholder="Grade"
              name="grade"
              onChange={(val) => setGrade(val.value)}
              options={gradeList?.map((item) => ({
                label: item.value,
                value: item.value,
              }))}
              required={true}
              classNamePrefix="filter-select"
              className="filterButton"
              autoComplete="new-password"
              // isDisabled={disbilityIndia === '1' ? true : emailFieldDisable}
            />

            <Form.Control.Feedback type="invalid">
              Please select Your State.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Control
            type="text"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Full Name"
            className="font-normal"
            required={true}
            onKeyDown={handleKeyDown}
            autoComplete="new-password"
            // disabled={disbilityIndia === '1' ? true : emailFieldDisable}
          />
          <Form.Control.Feedback type="invalid">
            Please enter the Name.
          </Form.Control.Feedback>

          <Form.Group
            className="mb-3 form-test"
            controlId="formName"
            id="otherSchool"
            style={{ width: "100%" }}
          >
            {/* <div className='testing'> */}
            <InputGroup>
              <InputGroup.Text className="country-code">
                {countryDetails.code}
              </InputGroup.Text>

              <Form.Control
                type="number"
                // placeholder="+91"
                onChange={(event) => setPhone(event.target.value)}
                classNamePrefix="form-control"
                required={true}
                onInput={(e) => {
                  data === "1"
                    ? (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10))
                    : (e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 14));
                }}
                autoComplete="new-password"
                className="input-style"
                // disabled={disbilityIndia === '1' ? true : emailFieldDisable}
              />
            </InputGroup>
            <Form.Control.Feedback type="invalid">
              Please enter Phone number.
            </Form.Control.Feedback>
            {/* </div> */}
          </Form.Group>

          {/* <InputGroup>
                        <Form.Control
                            type="number"
                            placeholder="+91"
                            disabled={false}
                            onChange={(event) => setPhone(event.target.value)}
                            classNamePrefix="form-control"
                            required={true}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }}
                            autoComplete="new-password"
                        />
                        
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                        Please enter Phone number.
                    </Form.Control.Feedback> */}

          <InputGroup>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              className="font-normal"
              // disabled={disbilityIndia === '1' ? true : emailFieldDisable}
              onChange={(event) => setEmail(event.target.value)}
              autoComplete="new-password"
            />
            {/* {data === '1' && */}

            {/* } */}
          </InputGroup>
          <Form.Text className="text-muted ms-auto">
            <Button
              // disabled={disbilityIndia === '1' ? true : getOtpBtnDisability}
              variant="contained"
              //disabled
              color="primary"
              onClick={(e) => handleGetOtp(e)}
              className={`ms-auto ${classes.getOtpButton}`}
            >
              Get Otp
            </Button>
          </Form.Text>

          {/* </Form.Group> */}

          {/* <TextField
                        id="outlined-name"
                        label="+91"
                        name='phone'
                        type="number"
                        required
                        className={classes.textField}
                        inputProps={{
                            max: 10,
                            maxLength: 10,
                            inputMode: 'numeric',
                            min: 10
                        }}
                        InputProps={{
                            endAdornment: (<Button variant="contained"
                                color="primary"
                                disabled={otpBtnDisable}
                                onClick={handleGetOtp}
                                className={classes.otpButton}>
                                Get Otp</Button>)
                        }}
                        onChange={(e) => setPhone(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    /> */}

          <div className="form-rows">
            <OtpInput
              value={otp}
              onChange={(val) => setOtp(val)}
              numInputs={6}
              separator={<span> </span>}
              className={classes.otpBoxes}
              inputStyle="test"
              isInputNum={true}
              disabled
              // isDisabled={disbilityIndia === '1' ? true : false}
            />
            <Button
              variant="contained"
              color="primary"
              // disabled
              // disabled={disbilityIndia === '1' ? true : verifiedBtnDisability}
              onClick={handleSubmitCode}
              className={classes.submitOtpButton}
            >
              {verifiedBtnDisability ? "Verified" : "Verify OTP"}
            </Button>
          </div>

          {showTimer && (
            <div className="d-flex justify-content-center">
              {!resendBtn ? (
                <p className="text-center">
                  {`${minutes.toString().padStart(2, "0")}:${seconds
                    .toString()
                    .padStart(2, "0")}`}
                </p>
              ) : (
                <Button disabled className={classes.resendButton} onClick={handleGetOtp}>
                  Resend
                </Button>
              )}
            </div>
          )}

          <div className="submit-button">
            <Button
              // disabled={disbilityIndia === '1' ? true : false}
              variant="contained"
              color="primary"
              type="submit"
              // disabled
              className={classes.submitButton}
            >
              Submit
            </Button>
          </div>
          <div className="consent-input c-style">
            By registering here, I agree to ICode&nbsp;
            <a
              href="https://icode.org/terms-of-use/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Terms & Conditions
            </a>{" "}
            and&nbsp;
            <a
              href="https://icode.org/privacy-policy/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StudentForm;
