import { Grid, Box, Typography } from '@mui/material';
import '../assets/css/index.css';
import Group_30257 from '../assets/images/Group_30257.png';
import Group_30258 from '../assets/images/Group_30258.png';
import Group_30259 from '../assets/images/Group_30259.png';
import Group_25185 from '../assets/images/Group_25185.svg';
import kid_1 from '../assets/images/kid_1.png';
import kid_2 from '../assets/images/kid_2.png';
import coding from '../assets/images/coding.png';
import develops from '../assets/images/develops.png';
import wave_new from '../assets/images/wave_new.png';
import background from '../assets/images/background.svg';
import {
  CardContainer,
  RoundImage,
  RankingCardContainer,
  RankingRoundImage,
  HackathonRoundImage,
  HackathonContainer,
} from '../assets/css/styles';
import StudentForm from './component/form';
// import Footer from '../second/components/Home/Footer';
import Awards from '../second/components/Home/Awards';
import Works from '../second/components/Home/Works';
import { useNavigate, useParams } from 'react-router-dom';
import { countryData } from '../Home';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

function StudentPage() {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const { countryName } = useParams();

  const [isIndia, setIsIndia] = useState(0)

  useEffect(() => {
    const countryList = countryData;
    if (countryName.toLowerCase() === 'india')
      setIsIndia(1)
    // if (countryName.toLowerCase() !== 'india') {
    //   return navigate('/');
    // }

    // for (let i = 0; i < countryList.length; i++) {
    //   if (countryName.toLowerCase() === countryList[i].value.toLowerCase()) {
    //     if (countryName.toLowerCase() !== 'india') {
    //       return window.location.href = 'https://2024.icode.org/lp/student';
    //     }
    //   }
    // }

    for (let i = 0; i < countryList.length; i++) {
      if (countryName.toLowerCase() === countryList[i].slug.toLowerCase()) {
        setData(countryList[i]);
      }
    }
  }, [countryName, navigate]);

  const isMobile = window.innerWidth < 600; // Set your own breakpoint value here
  return (
    <div>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundImage: isMobile
            ? `url(${background})`
            : `url(${background})`,
          backgroundColor: isMobile ? '#3eb7ff' : '#3eb7ff',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          height: { lg: '800px', xs: 'auto' },
          position: 'relative',
          paddingBottom: { lg: 0, xs: 5 },
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ display: 'flex', justifyContent: 'center', zIndex: 1000 }}
        >
          <div>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src={data.img}
                alt="img"
                height="160"
                width="auto"
                className="first-img"
              />
            </Box>
            <div className="CardContainer-box">
              <CardContainer sx={{ backgroundColor: '#fff' }}>
                <RoundImage src={Group_30257} alt="Group_30257" />
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      marginLeft: { lg: 14, xs: 9 },
                      fontWeight: 'bold',
                      fontSize: { lg: 16, xs: 14 },
                    }}
                  >
                    Each level of the ICode Global Hackathon is a live 60-minute
                    competition that is interactive and fun
                  </Typography>
                </div>
              </CardContainer>

              <CardContainer sx={{ backgroundColor: '#293D7C' }}>
                <RoundImage src={Group_30258} alt="Group_30257" />
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      marginLeft: { lg: 14, xs: 9 },
                      fontWeight: 'bold',
                      fontSize: { lg: 16, xs: 14 },
                      color: '#fff',
                    }}
                  >
                    The ICode Global Hackathon is mapped to CSTA* & Google CS
                    First* Coding Education standards.
                  </Typography>
                </div>
              </CardContainer>

              <CardContainer sx={{ backgroundColor: '#FFB60D' }}>
                <RoundImage src={Group_30259} alt="Group_30257" />
                <div>
                  <Typography
                    variant="body1"
                    sx={{
                      marginLeft: { lg: 14, xs: 9 },
                      fontWeight: 'bold',
                      fontSize: { lg: 16, xs: 14 },
                      color: '#fff',
                    }}
                  >
                    Over 3 million students from 73 nationalities participate
                    in ICode Global Hackathon annually, allowing students to
                    benchmark their skills with peers in their
                    respective age groups.
                  </Typography>
                </div>
              </CardContainer>
            </div>
          </div>
        </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1000,
            }}
          >
            <Box
              sx={{
                border: { lg: '2px solid #ccc', xs: '1px solid #ccc' },
                borderRadius: '8px',
                padding: { lg: '16px', xs: '10px' },
                backgroundColor: '#2379EB',
                width: { lg: '500px', xs: '100%' },
                height: 'auto',
              }}
            >
              {/* {isIndia !== 1 ?
                <>
                  <p className="form-header">Student Registration Form</p>
                  <StudentForm />
                </>
                : */}
                <Col className="styling">

                  <div>
                    <p className="form-header">Student Registration Form</p>
                    <StudentForm />
                  </div>

                  {/* <div className="overlay">
                    <Row style={{
                      textAlign: 'center',
                      fontSize: "18px",
                      borderRadius: "20px"
                    }} className='bg-warning p-3'>
                      <div style={{
                        color: 'white'
                      }}>
                        Registrations for ICode Global Hackathon 2024 are closed
                      </div>
                      <div style={{
                        color: 'black',
                        marginTop: '5px'
                      }}>
                        For any queries write to hackathon@icode.org
                        <br></br>
                        Click on the button below for Student Registration
                      </div>
                      <button type="submit"
                        // onClick={clickHandle}
                        className="btn-std-reg"
                      >Student Registration</button>
                    </Row>
                  </div> */}
                </Col>
            </Box>
          </Grid>



        <img src={wave_new} alt="back" className="back-img" />
      </Grid>

      {/* Ranking */}
      {/* <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: '#FEDF30',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: 7,
          paddingBottom: 7,
          marginTop: { lg: -6, xs: 0 },
          zIndex: '1 !important',
        }}
      >
        <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: 'bold' }}>
          Rankings
        </Typography>
        <div className="ranking-row">
          <RankingCardContainer>
            <RankingRoundImage src={Group_25185} alt="Profile picture" />
            <div>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: { lg: 16, xs: 14 } }}
              >
                Global Ranking
              </Typography>
            </div>
          </RankingCardContainer>

          <RankingCardContainer sx={{ marginLeft: 10 }}>
            <RankingRoundImage src={Group_25185} alt="Profile picture" />
            <div>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: { lg: 16, xs: 14 } }}
              >
                National Ranking
              </Typography>
            </div>
          </RankingCardContainer>
        </div>
      </Grid> */}

      {/* Global Hackathon */}
      {/* <Grid
        sx={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: 8,
          paddingBottom: { lg: 8, xs: 0 },
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: 3, fontWeight: 'bold', textAlign: 'center' }}
        >
          What’s ICode Global Hackathon?
        </Typography>

        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: { lg: 8, xs: 0 },
            paddingBottom: { lg: 0, xs: 4 },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={kid_1} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: {
                    lg: '#10284B',
                    md: '#10284B',
                    sm: '#10284B',
                    xs: '#fff',
                  },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                World’s Largest Coding Competition which enables K12 students to
                benchmark their coding skills with their peers globally
              </Typography>
            </HackathonContainer>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={coding} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: {
                    lg: '#10284B',
                    md: '#10284B',
                    sm: '#10284B',
                    xs: '#fff',
                  },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                Only platform which provides an opportunity to the kids to
                compete at the global levels
              </Typography>
            </HackathonContainer>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: { lg: 8, xs: 0 },
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={kid_2} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: {
                    lg: '#10284B',
                    md: '#10284B',
                    sm: '#10284B',
                    xs: '#fff',
                  },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                Global participation of 2.15 million student from 73+
                Nationalities
              </Typography>
            </HackathonContainer>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={develops} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: {
                    lg: '#10284B',
                    md: '#10284B',
                    sm: '#10284B',
                    xs: '#fff',
                  },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                Develops Computational thinking & logical reasoning skills
              </Typography>
            </HackathonContainer>
          </Grid>
        </Grid>
      </Grid> */}

      {/* How it works */}
      {/* <Works /> */}

      {/* SCHOOL AWARDS & PARTICIPANT PARTNER AWARD */}
      {/* <Awards /> */}

      {/* <Footer/> */}
    </div>
  );
}

export default StudentPage;
